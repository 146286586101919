<template>
  <div class="page-page container-fluid h-100">

    <div class="h-top mb-3">
      <router-link :to="{path: this.getRouterBackPath()}" class="button-back-empty">
        <span class="fa fa-arrow-left"></span>
      </router-link>
    </div>

    <div v-html="text" class="content"></div>
  </div>
</template>

<script>

export default {

  data: () => ({
    title: null,
    text: null,
  }),

  mounted() {

    this.doPageView();
  },

  methods: {

    async doPageView() {

      let apiHolder = await this.sendApiGet(['api/page/' + this.$route.params?.alias], {
        password: this.password
      });

      if (!apiHolder.isSuccess())
        return;

      this.title = apiHolder.data?.pageData?.title;
      this.text = apiHolder.data?.pageData?.text;
    },

    getRouterBackPath() {

      switch (this.$route.query?.back) {
        case 'user':
          return '/user/edit';
        case 'promo':
          return '/promo';
        default:
          return '/user/register';
      }
    }

  }

}
</script>

<style scoped>

  .content {
    padding-bottom: 30px;
  }

</style>